import { createIcon } from "@chakra-ui/icons"

const KeepCircleBrand = createIcon({
  displayName: "KeepCircleBrand",
  viewBox: "0 0 32 32",
  path: (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#48DBB4" />
      <path
        d="M16 0C7.16639 0 0 7.16639 0 16C0 24.8336 7.16639 32 16 32C24.8336 32 32 24.8336 32 16C31.9866 7.16639 24.8202 0 16 0ZM22.6958 12.4235H21.7008L18.6487 15.9866L21.7008 19.5496H22.7092V22.4134H16.2286V19.5765H17.237L15.2336 17.237H14.4134V19.5765H15.5563V22.4134H9.27731V19.5496H10.6353V16V12.4235H9.27731V9.55966H10.8235V10.6218H11.6571V9.55966H13.1765V10.6218H14.0101V9.55966H15.5429V12.3832H14.4V14.7227H15.2202L17.2235 12.3832H16.2151V9.55966H22.6958V12.4235Z"
        fill="#0A0806"
      />
    </svg>
  ),
})

export default KeepCircleBrand
