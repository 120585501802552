import { createIcon } from "@chakra-ui/icons"

const NuLight = createIcon({
  displayName: "NuLight",
  viewBox: "0 0 21 20",
  path: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9065 4.17105L13.5184 4.84054C13.4868 4.85648 13.4749 4.89234 13.4907 4.92024L15.6025 9.45521C16.3894 11.1409 16.1759 12.4201 14.9816 12.9979C14.0167 13.4642 13.1823 13.1374 12.5337 12.0017L10.0345 6.63381C9.02214 4.45797 7.63805 3.88014 6.00088 4.67317C4.93316 5.18724 4.31626 6.10778 4.32812 7.2754C4.32812 7.28735 4.32417 7.30329 4.31626 7.31126C4.30044 7.33119 4.2688 7.33916 4.24508 7.3272C4.23321 7.32322 4.22135 7.31126 4.2174 7.29931L3.67167 6.12771C3.66376 6.11177 3.6519 6.1038 3.63608 6.09583C3.62026 6.09184 3.60445 6.09184 3.59258 6.09981L2.20059 6.77328C2.17291 6.78922 2.15709 6.82509 2.17291 6.85298L6.00879 15.0821C6.0167 15.098 6.02856 15.106 6.04438 15.114C6.0602 15.118 6.07602 15.118 6.09184 15.11L7.47987 14.4405C7.51151 14.4246 7.52337 14.3887 7.50755 14.3608L5.45516 9.95334C4.6168 8.16008 4.77894 6.86095 6.0602 6.24327C7.04488 5.76905 7.8081 6.08786 8.49618 7.30329C8.49618 7.30329 8.49618 7.30728 8.50014 7.30728L10.9757 12.6193C11.9287 14.6676 13.4156 15.373 14.9816 14.6158C16.1917 14.03 16.6939 13.1493 16.6662 11.9937C16.6662 11.9658 16.686 11.9419 16.7097 11.9339C16.7374 11.9259 16.7651 11.9419 16.7769 11.9658L17.3306 13.1533C17.3424 13.1772 17.3622 13.1892 17.3859 13.1892C17.3938 13.1892 17.4018 13.1852 17.4097 13.1852L18.7977 12.5117C18.8214 12.5038 18.8333 12.4799 18.8333 12.4559C18.8333 12.448 18.8293 12.44 18.8254 12.432L14.9895 4.20293C14.9697 4.17105 14.9341 4.1591 14.9065 4.17105Z"
        fill="#AEABB3"
      />
    </svg>
  ),
})

export default NuLight
