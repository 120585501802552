import { createIcon } from "@chakra-ui/icons"

const NuCircleBrand = createIcon({
  displayName: "NuCircleBrand",
  viewBox: "0 0 32 32",
  path: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#1E65FF" />
      <path
        d="M21.2878 9.33877L19.6222 10.1422C19.5842 10.1613 19.57 10.2043 19.5889 10.2378L22.123 15.6798C23.0673 17.7026 22.8111 19.2376 21.378 19.931C20.2201 20.4905 19.2188 20.0984 18.4405 18.7355L15.4414 12.2941C14.2266 9.68308 12.5657 8.98968 10.6011 9.94131C9.31984 10.5582 8.57956 11.6628 8.5938 13.064C8.5938 13.0783 8.58905 13.0975 8.57956 13.107C8.56058 13.1309 8.52261 13.1405 8.49414 13.1261C8.47991 13.1214 8.46567 13.107 8.46092 13.0927L7.80606 11.6868C7.79656 11.6676 7.78233 11.6581 7.76335 11.6485C7.74436 11.6437 7.72538 11.6437 7.71115 11.6533L6.04076 12.4614C6.00754 12.4806 5.98856 12.5236 6.00754 12.5571L10.6106 22.432C10.6201 22.4511 10.6343 22.4607 10.6533 22.4703C10.6723 22.475 10.6913 22.475 10.7103 22.4655L12.3759 21.6621C12.4139 21.643 12.4281 21.5999 12.4091 21.5665L9.94624 16.2775C8.94021 14.1256 9.13477 12.5667 10.6723 11.8254C11.8539 11.2564 12.7698 11.6389 13.5955 13.0975C13.5955 13.0975 13.5955 13.1022 13.6002 13.1022L16.5708 19.4767C17.7145 21.9347 19.4988 22.7811 21.378 21.8725C22.8301 21.1695 23.4327 20.1127 23.3995 18.7259C23.3995 18.6925 23.4232 18.6638 23.4517 18.6542C23.4849 18.6446 23.5181 18.6638 23.5324 18.6925L24.1967 20.1175C24.211 20.1462 24.2347 20.1605 24.2632 20.1605C24.2727 20.1605 24.2822 20.1558 24.2916 20.1558L25.9573 19.3476C25.9858 19.338 26 19.3093 26 19.2806C26 19.2711 25.9953 19.2615 25.9905 19.252L21.3875 9.37703C21.3637 9.33877 21.321 9.32442 21.2878 9.33877Z"
        fill="white"
      />
    </svg>
  ),
})

export default NuCircleBrand
