import { createIcon } from "@chakra-ui/icons"

export const tBTCFill = createIcon({
  displayName: "tBTCFill",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M12 23.2266C18.2003 23.2266 23.2266 18.2003 23.2266 12C23.2266 5.79974 18.2003 0.773438 12 0.773438C5.79974 0.773438 0.773438 5.79974 0.773438 12C0.773438 18.2003 5.79974 23.2266 12 23.2266Z"
      fill="currentColor"
      className="fill"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1121 7.60528C17.6192 7.73783 18.8125 8.20191 18.945 9.58446C19.0436 10.5964 18.6211 11.202 17.9442 11.5481C19.0582 11.8173 19.7558 12.479 19.6174 13.963C19.4468 15.8054 18.0726 16.2993 16.11 16.4111V17.2814H14.9427V16.437C14.6402 16.4374 14.3301 16.4346 14.0104 16.4277V17.2811H12.8441V16.4069C12.5714 16.4052 11.3131 16.3996 11.0303 16.3996L11.039 15.3853H12.0125V8.64211L11.0386 8.63657L11.0421 7.6077C11.0421 7.6077 12.5942 7.60909 12.8469 7.60424V6.71899H14.0131V7.56652C14.3249 7.56064 14.6385 7.55476 14.9451 7.55476V6.71969H16.1121V7.60528ZM14.2642 15.018C15.2203 15.0317 17.3098 15.0617 17.3098 13.7314H17.3088C17.3085 12.3707 15.2984 12.418 14.3198 12.441C14.2112 12.4435 14.1153 12.4458 14.0363 12.4458V15.0157C14.1016 15.0157 14.1783 15.0168 14.2642 15.018ZM14.2308 11.2718C15.0289 11.285 16.7682 11.314 16.7682 10.1053C16.7688 8.86914 15.0969 8.9126 14.2787 8.93387C14.1864 8.93627 14.105 8.93838 14.038 8.93835V11.2695C14.0931 11.2695 14.1581 11.2706 14.2308 11.2718Z"
      fill="white"
      className="transparent"
    />,
    <path
      d="M8.25895 9.17957H6.37909V11.0594H8.25895V9.17957Z"
      fill="white"
      className="transparent"
    />,
    <path
      d="M10.1388 11.0594L8.25895 11.0594L8.25897 12.9393H10.1388V11.0594Z"
      fill="white"
      className="transparent"
    />,
    <path
      d="M4.4989 11.0594L6.37909 11.0594L6.37876 12.9393H4.4989V11.0594Z"
      fill="white"
      className="transparent"
    />,
    <path
      d="M8.25897 12.9393H6.37876L6.37909 14.8191H8.25895L8.25897 12.9393Z"
      fill="white"
      className="transparent"
    />,
  ],
})
