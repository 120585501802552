import { createIcon } from "@chakra-ui/icons"

export const IoHomeOutlineSharp = createIcon({
  displayName: "IoHomeOutlineSharp",
  viewBox: "0 0 32 29",
  path: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.742 2.26923L16.0623 3.00272L15.3864 2.26577L16.0658 1.64258L16.742 2.26923Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9998 6.22032C20.0771 5.36316 19.2329 4.57953 18.5438 3.94021L16.742 2.26923C16.7419 2.26915 16.742 2.26923 16.0623 3.00272C15.3864 2.26577 15.3864 2.26569 15.3864 2.26577L14.8829 2.72773C14.5609 3.02331 14.0981 3.44819 13.5351 3.96541C12.409 4.99983 10.8818 6.40368 9.27759 7.88133C6.07374 10.8324 2.5491 14.0899 1.30832 15.2777L0.585938 15.9692L1.96893 17.4139L2.69131 16.7224C2.99772 16.4291 3.4474 16.0059 3.99981 15.4898V30H4.99981H12.9998V20H18.9998V30H26.9997H27.9998V15.4837C28.5383 15.9903 28.9861 16.414 29.3083 16.7224L30.0307 17.4139L31.4137 15.9692L30.6913 15.2777C29.8125 14.4364 28.017 12.7545 25.9998 10.8731V3.00004H20.9998V6.22032ZM22.9998 8.07935C23.3312 8.38755 23.6655 8.69858 23.9998 9.00979V5.00004H22.9998V8.07935ZM21.3465 9.27295C22.9188 10.7351 24.5722 12.2749 25.9998 13.6093V28H20.9998V19.0001V18H10.9998V19V28H5.99981V13.6302C7.4124 12.3211 9.05797 10.8027 10.6326 9.35239C12.2359 7.87554 13.7624 6.47233 14.8881 5.43829L16.0589 4.36314L17.1835 5.40634C18.2809 6.42452 19.7719 7.80874 21.3465 9.27295Z"
        fill="currentColor"
      />
    </svg>
  ),
})
