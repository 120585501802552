import { createIcon } from "@chakra-ui/icons"

const TransactionError = createIcon({
  displayName: "TransactionError",
  viewBox: "0 0 91 124",
  path: (
    <svg
      width="91"
      height="124"
      viewBox="0 0 91 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1130_41684)">
        <path
          d="M48.856 32.8489C52.1723 28.947 57.2857 21.1093 60.7281 17.0963C61.4366 16.2701 60.8475 14.9952 59.7607 14.9952H53.4961L59.4051 1.60502C59.6574 1.03331 58.8804 0.577806 58.5062 1.07826L44.2183 20.2416C43.8019 20.8006 44.2573 21.5877 44.9488 21.5021L52.0411 20.6267L47.8216 32.2424C47.5897 32.8828 48.4158 33.3672 48.856 32.8489Z"
          fill="#F77D48"
        />
        <path
          d="M81.7444 115.106L10.4546 121.125C5.04166 121.582 1.12433 116.052 3.34031 111.082L33.543 43.3445C35.8343 38.2058 42.8776 37.6112 45.9942 42.2935L87.0813 104.012C90.0963 108.54 87.1574 114.649 81.7444 115.106Z"
          fill="#FFF5F5"
        />
        <path
          d="M81.9712 117.809L10.6814 123.828C7.17544 124.124 3.86585 122.584 1.82932 119.709C-0.207221 116.833 -0.566111 113.195 0.869452 109.976L31.0721 42.2386C32.556 38.9101 35.6065 36.7403 39.2318 36.4341C42.8571 36.1279 46.2268 37.7556 48.2456 40.7889L89.3327 102.507C91.2854 105.441 91.5393 109.088 90.0115 112.264C88.4828 115.44 85.4771 117.513 81.9712 117.809ZM39.6864 41.8382C38.0544 41.9756 36.6815 42.9528 36.0137 44.4508L5.81013 112.188C5.16429 113.637 5.32596 115.275 6.24266 116.569C7.15935 117.863 8.64909 118.556 10.2269 118.423L81.5166 112.404C83.0944 112.271 84.4478 111.337 85.1352 109.908C85.8225 108.479 85.709 106.837 84.8296 105.517L43.7434 43.7976C42.8343 42.4328 41.3175 41.6999 39.6864 41.8382Z"
          fill="#F77D48"
        />
        <path
          d="M38.0155 104.744C37.9385 103.823 38.0299 102.958 38.2931 102.149C38.5555 101.341 38.9618 100.625 39.5128 100.002C40.0639 99.3803 40.7461 98.8722 41.5612 98.4804C42.3755 98.0893 43.3108 97.8476 44.3663 97.7585C45.421 97.6695 46.391 97.75 47.273 97.9977C48.1558 98.2463 48.921 98.6322 49.5677 99.153C50.2152 99.6747 50.735 100.313 51.1285 101.065C51.5221 101.819 51.7575 102.655 51.8353 103.577C51.9124 104.497 51.8192 105.355 51.556 106.15C51.2928 106.946 50.8856 107.654 50.3346 108.277C49.7836 108.899 49.0937 109.407 48.265 109.8C47.4364 110.193 46.4943 110.435 45.4388 110.524C44.3833 110.613 43.4209 110.532 42.5533 110.283C41.6848 110.033 40.9272 109.647 40.2797 109.126C39.633 108.605 39.1125 107.975 38.7206 107.234C38.3278 106.494 38.0934 105.664 38.0155 104.744ZM49.037 91.8192L38.768 92.6861L34.3572 57.648L47.5354 56.5352L49.037 91.8192Z"
          fill="#F77D48"
        />
      </g>
      <defs>
        <clipPath id="clip0_1130_41684">
          <rect
            width="91"
            height="123"
            fill="white"
            transform="translate(0 0.863281)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
})

export default TransactionError
