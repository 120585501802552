import { createIcon } from "@chakra-ui/icons"

export const Arbitrum = createIcon({
  displayName: "Arbitrum",
  viewBox: "0 0 1080 1080",
  path: (
    <svg
      width="1080"
      height="1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#213147"
        d="M171.5,363.6v352.8c0,22.5,12,43.4,31.5,54.6l305.5,176.4c19.5,11.2,43.5,11.2,63,0L877.1,771
          c19.5-11.2,31.5-32.1,31.5-54.6V363.6c0-22.5-12-43.4-31.5-54.6L571.6,132.6c-19.5-11.2-43.5-11.2-63,0L203,309
          C183.5,320.3,171.5,341.1,171.5,363.6z"
      />
      <g>
        <g>
          <g>
            <path
              fill="#12AAFF"
              d="M606.7,608.4l-43.6,119.5c-1.2,3.3-1.2,6.9,0,10.3l75,205.6l86.7-50.1L620.7,608.4
                C618.4,601.9,609.1,601.9,606.7,608.4z"
            />
          </g>
          <g>
            <path
              fill="#12AAFF"
              d="M694.1,407.5c-2.4-6.6-11.7-6.6-14,0L636.5,527c-1.2,3.3-1.2,6.9,0,10.3l122.8,336.6l86.7-50.1L694.1,407.5
                z"
            />
          </g>
        </g>
      </g>
      <path
        fill="#9DCCED"
        d="M540,145.8c2.1,0,4.3,0.6,6.2,1.6l330.6,190.9c3.8,2.2,6.2,6.4,6.2,10.7v381.8c0,4.4-2.4,8.5-6.2,10.7
          L546.2,932.4c-1.8,1.1-4.1,1.6-6.2,1.6c-2.1,0-4.3-0.6-6.2-1.6L203.2,741.7c-3.8-2.2-6.2-6.4-6.2-10.7V349.1
          c0-4.4,2.4-8.5,6.2-10.7l330.6-190.9C535.7,146.4,537.9,145.8,540,145.8 M540,90c-11.7,0-23.6,3-34.1,9.2L175.3,290
          c-21.1,12.2-34.1,34.7-34.1,59.1v381.8c0,24.4,13,46.9,34.1,59.1L506,980.9c10.6,6.1,22.3,9.2,34.1,9.2c11.7,0,23.6-3,34.1-9.2
          L904.9,790c21.1-12.2,34.1-34.7,34.1-59.1V349.1c0-24.4-13-46.9-34.1-59.1L574.1,99.2C563.6,93.1,551.8,90,540,90L540,90z"
      />
      <polygon
        fill="#213147"
        points="321.3,874.3 351.8,791 413,841.9 355.8,894.2"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M512.2,321.8h-83.8c-6.3,0-11.9,3.9-14,9.8L234.6,824.2l86.7,50.1l197.9-542.5
            C521,327,517.4,321.8,512.2,321.8z"
        />
        <path
          fill="#FFFFFF"
          d="M658.9,321.8H575c-6.3,0-11.9,3.9-14,9.8L355.8,894.1l86.7,50.1l223.3-612.3C667.6,327,664,321.8,658.9,321.8
            z"
        />
      </g>
    </svg>
  ),
})
