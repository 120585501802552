export enum ModalType {
  SelectWallet = "SELECT_WALLET",
  UpgradeToT = "UPGRADE_TO_T",
  UpgradedToT = "UPGRADED_TO_T",
  TransactionIsPending = "TRANSACTION_IS_PENDING",
  TransactionIsWaitingForConfirmation = "TRANSACTION_IS_WAITING_FOR_CONFIRMATION",
  TransactionFailed = "TRANSACTION_FAILED",
  StakingChecklist = "STAKING_CHECKLIST",
  ConfirmStakingParams = "CONFIRM_STAKING_PARAMS",
  StakeSuccess = "STAKE_SUCCESS",
  UnstakeT = "UNSTAKE_T",
  UnstakeTStep2 = "UNSTAKE_T_STEP_2",
  UnstakeSuccess = "UNSTAKE_SUCCESS",
  TopupT = "TOP_UP_T",
  TopupLegacyStake = "TOP_UP_LEGACY_STAKE",
  TopupTSuccess = "TOP_UP_T_SUCCESS",
  ClaimingRewards = "CLAIMING_REWARDS",
  ClaimingRewardsSuccess = "CLAIMING_REWARDS_SUCCESS",
  NewAppsToAuthorize = "NEW_APPS_TO_AUTHORIZE",
  DeauthorizeApplication = "DEAUTHORIZE_APPLICATION",
  SubmitStake = "SUBMIT_STAKE",
  NewStakerAuthorizeStakingApplication = "NEW_STAKER_AUTHORIZE_STAKING_APPLICATION",
  StakeSuccessOLD = "STAKE_SUCCESS_OLD",
  MapOperatorToStakingProvider = "MAP_OPERATOR_TO_STAKING_PROVIDER",
  MapOperatorToStakingProviderConfirmation = "MAP_OPERATOR_TO_STAKING_PROVIDER_CONFIRMATION",
  MapOperatorToStakingProviderSuccess = "MAP_OPERATOR_TO_STAKING_PROVIDER_SUCCESS",
  AuthorizeStakingApps = "AUTHORIZE_STAKING_APPS",
  StakingApplicationsAuthorized = "STAKING_APPLICATIONS_AUTHORIZED",
  IncreaseAuthorization = "INCREASE_AUTHORIZATION",
  IncreaseAuthorizationSuccess = "INCREASE_AUTHORIZATION_SUCCESS",
  ConfirmDeauthorization = "CONFIRM_DEAUTHORIZATION",
  DeauthorizationCompleted = "DEAUTHORIZATION_COMPLETED",
  DeauthorizationInitiated = "DEAUTHORIZATION_INITIATED",
  Analytics = "ANALYTICS",
  NewTBTCApp = "NEW_TBTC_APP",
  FeedbackSubmission = "FEEDBACK_SUBMIT",
  GenerateNewDepositAddress = "TBTC_GENERATE_NEW_DEPOSIT_ADDRESS",
  InitiateUnminting = "INITIATE_UNMINTING",
  TACoCommitment = "TACO_COMMITMENT",
  TACoCommitmentSuccess = "TACO_COMMITMENT_SUCCESS",
}
