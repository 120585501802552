import { createIcon } from "@chakra-ui/icons"

export const tBTCOutline = createIcon({
  displayName: "tBTCOutline",
  viewBox: "0 0 32 32",
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29.3687C23.3834 29.3687 29.3687 23.3834 29.3687 16C29.3687 8.61664 23.3834 2.63125 16 2.63125C8.61664 2.63125 2.63125 8.61664 2.63125 16C2.63125 23.3834 8.61664 29.3687 16 29.3687ZM30.9688 16C30.9688 24.267 24.267 30.9688 16 30.9688C7.73299 30.9688 1.03125 24.267 1.03125 16C1.03125 7.73299 7.73299 1.03125 16 1.03125C24.267 1.03125 30.9688 7.73299 30.9688 16Z"
      fill="currentColor"
    />,
    <path
      d="M13.5184 14.7458H11.012V17.2523H13.5184V14.7458Z"
      fill="currentColor"
    />,
    <path
      d="M11.0119 12.2395H8.50543V14.746H11.0119V12.2395Z"
      fill="currentColor"
    />,
    <path
      d="M8.50502 14.7458H5.99854V17.2523H8.50502V14.7458Z"
      fill="currentColor"
    />,
    <path
      d="M11.0119 17.2522H8.50543V19.7587H11.0119V17.2522Z"
      fill="currentColor"
    />,
    <path
      d="M25.26 12.7794C25.0833 10.936 23.4922 10.3172 21.4827 10.1405V8.95966H19.9268V10.0731C19.518 10.0731 19.0999 10.0809 18.6842 10.0888V8.95874H17.1291V10.1391C16.7923 10.1455 14.7228 10.1437 14.7228 10.1437L14.7182 11.5155L16.0166 11.5229V20.5138H14.7186L14.7071 21.8663C15.0841 21.8663 16.7618 21.8737 17.1254 21.876V23.0415H18.6805V21.9036C19.1068 21.9129 19.5203 21.9166 19.9236 21.9161V23.042H21.48V21.8815C24.0967 21.7325 25.9291 21.074 26.1565 18.6174C26.3411 16.6388 25.4109 15.7565 23.9255 15.3975C24.8281 14.9361 25.3924 14.1281 25.2609 12.7789L25.26 12.7794ZM23.0797 18.3087C23.0797 20.2416 19.7708 20.0206 18.7151 20.021V16.5945C19.7704 16.5945 23.0779 16.2931 23.0783 18.3087H23.0797ZM22.3576 13.4738C22.3576 15.2323 19.5964 15.0261 18.7174 15.0261V11.9179C19.5978 11.9183 22.3585 11.6396 22.3576 13.4738Z"
      fill="currentColor"
    />,
  ],
})
