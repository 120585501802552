import { createIcon } from "@chakra-ui/icons"

const ThresholdPurple = createIcon({
  displayName: "ThresholdPurple",
  viewBox: "0 0 32 32",
  path: (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8666 0.0334473H21.2903V3.55867H31.8666V0.0334473ZM14.2372 3.55867H10.7118V7.08601H14.2372V3.55867ZM10.7118 21.189H14.2372V10.6112H10.7118V21.189ZM17.7627 3.55867V7.08601H21.2903V3.55867H17.7627ZM17.7627 21.189H21.2903V10.6112H17.7627V21.189ZM17.7627 0.0334473H14.2372V3.55867H17.7627V0.0334473ZM0.133301 0.0334473V3.55867H10.7118V0.0334473H0.133301ZM31.8666 10.6112V7.08601H21.2903V10.6112H31.8666ZM14.2372 7.08601V10.6112H17.7627V7.08601H14.2372ZM0.133301 7.08601V10.6112H10.7118V7.08601H0.133301Z"
        fill="#7D00FF"
      />
    </svg>
  ),
})

export default ThresholdPurple
